import React from "react"
import PropTypes from "prop-types"
import { Link, graphql, StaticQuery } from "gatsby"
import Image from "../components/image"
import Footer from "../components/footer/footer.component"
import Header from "../components/header/header.component"
import SEO from "../components/seo"

const Blog = props => {
  const { data } = props
  const { edges: posts } = data.allMarkdownRemark

  return (
    <div className="columns is-multiline">
      <SEO title="Blog" url="https://nibrasplay.co.id/blog" />
      <Header />
      <div className="container-blog">
        <div className="sections">
          <h3>Blog Posting Terbaru</h3>
          <div className="parent">
            {posts &&
              posts.map(({ node: post }) => (
                <div className="child" key={post.id} >
                  <article>
                    <header>
                      <p className="post-meta">
                        <Link
                          className="title has-text-primary is-size-4"
                          to={post.frontmatter.path}
                        >
                          <h4> {post.frontmatter.blogtitle}</h4>

                          <span className="date">{post.frontmatter.date}</span>
                        </Link>
                      </p>

                      <div className="featured-image">
                        {/* <Image
                          alt="family"
                          name={post.frontmatter.blogimage.replace(
                            "images/",
                            ""
                          )}
                        /> */}
                      </div>

                      <p className="excerpt">{post.excerpt}</p>
                      <Link
                        className="title has-text-primary is-size-4"
                        to={post.frontmatter.path}
                      >
                        <button>Selengkapnya...</button>
                      </Link>
                    </header>
                    <br />
                    <br />
                  </article>
                </div>
              ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

Blog.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query BlogQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { page: { ne: true } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 300)
              id
              frontmatter {
                path
                blogtitle
                author
                blogimage
                date(formatString: "MMMM DD, YYYY")
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <Blog data={data} count={count} />}
  />
)
